/* eslint-disable*/
/* -------------------------------------------------------------------------- */
/*                                Universal contact form ajax submission                                  */
/* -------------------------------------------------------------------------- */
const formInit = () => {
  const zforms = document.querySelectorAll("[data-form]");

  if (zforms.length) {
    zforms.forEach((form) => {
      form.addEventListener('submit', (e) => {
        e.preventDefault();
        const feedbackEl = form.querySelector('.feedback');
        const formData = {};
        Array.from(form.elements).forEach(el => {
          if (el.type !== 'submit') {
            formData[el.name] = el.value
          }
        })
        var emailBody = `<div class="alert alert-success alert-dismissible" role="alert">
            <button type="button" class="btn-close fs--1" data-bs-dismiss="alert" aria-label="Close"></button>
            Your message has been sent successfully.<br />
            We will be in touch soon.<br />
            </div>`;
        if (formData.Art == 'Login') emailBody = `<div class="alert alert-danger alert-dismissible" role="alert">
          <button type="button" class="btn-close fs--1" data-bs-dismiss="alert" aria-label="Close"></button>
          Please check your Email and Password are correct.
          </div>`;
        if (formData.Art == 'Signup') emailBody = `<div class="alert alert-success alert-dismissible" role="alert">
            <button type="button" class="btn-close fs--1" data-bs-dismiss="alert" aria-label="Close"></button>
            We received your account request.<br />
            We will be in touch soon.<br />
            </div>`;
        

        window.Email.send({
          Host: "smtp.gmail.com",
          Username: "o.weiss@d2g.one",
          Password: "HlvB!2020kb!",
          To: "o.weiss@d2g.one",
          From: formData.email,
          Subject: "Nachricht von D2G.one",
          Body: `
            <p><b>Nachricht von der Website D2G.one:</b></p>
            <p><b>Name:</b><br />${formData.name}</p>
            <p><b>Email:</b><br />${formData.email}</p>
            <p><b>Telefon:</b><br />${formData.phone}</p>
            <p><b>Formular:</b><br />${formData.Art}</p>
            <p><b>Nachricht:</b><br />${formData.message}</p>
          `
        }).then(
          message => {
            feedbackEl.innerHTML = emailBody
          }
        ).catch(error => {
          feedbackEl.innerHTML = `<div class="alert alert-danger alert-dismissible" role="alert">
          <button type="button" class="btn-close fs--1" data-bs-dismiss="alert" aria-label="Close"></button>
          Your message eas not sent.
        </div>`
        })
      })
    });
  }
};

export default formInit;